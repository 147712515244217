import React from 'react'
import Layout from '../components/layout'
import { makeStyles } from '@material-ui/core/styles'
import { graphql } from "gatsby"
import InstagramIcon from '../images/instagram_pink.png'
import TwitterIcon from '../images/twitter_pink.png'
import Img from "gatsby-image"
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useStaticQuery } from 'gatsby'

const useStyle = makeStyles({
  titleName: {
    color: '#F88A17',
    lineHeight: '3rem',
    display: "flex",
    alignItems: "center"
  },
  subtitle: {
    color: '#FC9C74',
    fontFamily: 'Aladin, monospace',
  },
  self: props => ({
    display: 'flex',
    alignItems: 'top',
    flexDirection: props.flexDirection,
  }),
  titleBox: props => ({
    display: 'flex',
    // flexDirection: 'row',
    alignItems: 'center',
  }),
  icon: {
    width: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  text: {
    display: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
})
const imagesQuery = graphql`
  query AboutQuery {
    portraitImage : file(relativePath : {eq :"Portrait.jpg"}) {
      id
      name
      childImageSharp {
	fluid(maxWidth : 1200){
	  ...GatsbyImageSharpFluid_withWebp_noBase64
	}
      }
    }
  }
`
const About = () => {
  let props
  const phoneMatches = useMediaQuery('(min-width: 800px)')
  const images = useStaticQuery(imagesQuery)
  const portraitFluid = images.portraitImage.childImageSharp.fluid
  // const desktopMatches = useMediaQuery('(max-width: 1200px')
  if (phoneMatches) {
    props = {
      flexDirection: 'row',
    }
  } else {
    props = {
      flexDirection: 'column',
    }
  }
  const classes = useStyle(props)
  return (
    <>
      <Layout>
        <br></br>
        <div className={classes.self}>
          <div classeName={classes.titleBox}>
            <div className={classes.text}>
              <h2 className={classes.subtitle}>
                Artist based in Montreal, Canada
              </h2>
              <div>
                Hello people! Thank you so much for taking the time to visit my shop 人´∀｀{')'} <br />
                I’m kira, a Sino-Canadian college student who really enjoys creating JoJo fanart and making content for my original characters. I’m passionate about incorporating elements of drama and storytelling into my art — as much as I am about working towards improving my artistic skills.
                <br /><br />
                I’ve still got a very long way to go, but every encouragement and ounce of support is a stepping stone towards the peak of my journey! ♡
              </div>
            </div>
            <br></br>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "140px", margin: "auto" }}>

              <div className={classes.icon}>
                <a
                  target="blank"
                  rel="noopener noreferrer"
                  href="https://www.instagram.com/_kiragyu/"
                  id="logo"
                >
                  <img src={InstagramIcon} height="60" alt="instagram icon" />
                </a>
              </div>
              <div className={classes.icon}>
                <a
                  target="blank"
                  rel="noopener noreferrer"
                  href="https://www.twitter.com/_kiragyu/"
                  id="logo"
                >
                  <img src={TwitterIcon} height="60" alt="twitter icon" />
                </a>
              </div>
            </div>
          </div>
          <Img fluid={portraitFluid} height="550" style={{ width: "90rem", maxWidth: "50vw", marginLeft : !phoneMatches ? "auto" : "2rem", marginRight : !phoneMatches ? "auto" : "0rem", marginTop : !phoneMatches ? "2rem" : "0rem" }} />
        </div>
      </Layout>
    </>
  )
}
export default About
